.dashboardLayout {
  height: 100%;
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar content'
    'sidebar content';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;

  .sidebar {
    grid-area: sidebar;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content {
    grid-area: content;
    grid-template-areas:
      'main'
      'footer';
    overflow-y: auto;

    .main {
      grid-area: main;
      background-color: var(--offwhite-light);
      overflow-x: auto;
    }

    .footer {
      grid-area: footer;
    }
  }
}
