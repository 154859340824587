:root {
  /* COLORS */
  --primary: rgb(69, 135, 239); /* #4586ef */
  --primaryDark: #3151aa;
  --primary-10: rgba(69, 135, 239, 0.1);
  --primary-05: rgba(69, 135, 239, 0.05);
  --success: rgb(46, 176, 98); /* #2eb062 */
  --success-10: rgba(46, 176, 98, 0.1);
  --danger: rgb(250, 74, 87); /* #fa4a57 */
  --warning: rgb(245, 166, 35); /* #f5a523 */
  --default: rgb(49, 81, 170); /* #3151aa */
  --default-80: rgba(49, 81, 170, 0.8);
  --default-10: rgba(49, 81, 170, 0.1);
  --active: rgb(44, 82, 130); /* #2c5282 */
  --default-light: #4587ef;
  --default-lighter: rgb(43, 108, 176); /* #2B6CB0 */
  --default-lighter-10: rgba(43, 108, 176, 0.1);

  --gray: rgb(81, 94, 107); /* #515e6b */
  --gray-light: rgb(132, 146, 166); /* #8492a6 */
  --gray-lighter: rgb(151, 158, 166); /* #979ea6 */
  --gray-lightest: rgb(217, 217, 217); /* #d9d9d9 */
  --new-gray: #cbd5e0;
  --new-gray-light: #edf2f7;
  --new-gray-lighter: #e2e8f0;
  --new-gray-darkest: #4a5568;
  --new-gray-darker: #718096;

  --red-light: #f56565;
  --red-dark: #c61b42;

  --brown: #c05621;

  --offwhite-dark: rgb(234, 234, 234); /* #eaeaea */
  --offwhite: rgb(241, 241, 241); /* #f1f1f1 */
  --offwhite-light: rgb(247, 247, 247); /* #f7f7f7 */

  --border-light: rgb(229, 233, 242); /* #e5e9f2 */
  --shadow: rgba(158, 158, 158, 0.5);

  --white: rgb(255, 255, 255); /* #ffffff */
  --white-darker: #f7fafc;

  --black: rgb(1, 23, 56); /* #011738 */
  --black-lighter: #202020;
  --black-lightest: #2d3748;

  --true-black: rgba(0, 0, 0);
  --true-black-60: rgba(0, 0, 0, 0.6);
  --true-black-70: rgba(0, 0, 0, 0.7);
  --true-black-80: rgba(0, 0, 0, 0.8);

  /* FONT SIZES */
  --font-2xl: 30px;
  --font-xl: 24px;
  --font-lg: 20px;
  --font-md: 18px;
  --font-base: 16px;
  --font-sm: 14px;
  --font-xs: 12px;

  /* SPACING */
  --spacing-3xs: 2px;
  --spacing-2xs: 5px;
  --spacing-xs: 10px;
  --spacing-sm: 15px;
  --spacing-base: 20px;
  --spacing-md: 25px;
  --spacing-lg: 30px;
  --spacing-xl: 40px;
  --spacing-2xl: 50px;
  --spacing-3xl: 60px;
  --spacing-4xl: 80px;
  --spacing-5xl: 100px;

  /* ICONS */
  --icon-3xs: 12px;
  --icon-2xs: 14px;
  --icon-xs: 16px;
  --icon-sm: 18px;
  --icon-base: 20px;
  --icon-md: 24px;
  --icon-lg: 32px;
  --icon-xl: 40px;
  --icon-2xl: 52px;

  /* TYPOGRAPHY */
  --font-bold: bold;
  --font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  /* BORDER */
  --border-radius: 10px;
  --border-solid-light: 1px solid var(--border-light);

  /* TRANSITIONS */
  --transition-short: 150ms;
  --transition-normal: 300ms;

  /* EASINGS */
  --ease-back: cubic-bezier(0.34, 1.56, 0.64, 1);

  /* WIDTH */
  --width-dashboard: 1280px;
}
