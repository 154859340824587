.loader {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    background-color: var(--white);
    border-bottom: var(--border-solid-light);
    padding: 0 var(--spacing-xl);
    height: 82px;
    display: flex;
    align-items: center;

    .headerTitle {
      height: var(--font-sm);
    }

    .headerSubtitle {
      margin-top: var(--spacing-xs);

      li {
        height: var(--font-xs);
      }
    }
  }
}
