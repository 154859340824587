.skeleton {
  :global {
    .ant-skeleton-header {
      padding-right: var(--spacing-sm);

      &:only-child {
        padding: 0;
      }
    }

    .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: var(--spacing-base) 0 0;
    }

    .ant-skeleton-paragraph {
      margin-bottom: 0;
    }

    .ant-skeleton-paragraph > li + li {
      margin-top: var(--spacing-xs);
    }
  }

  &:not(:global(.ant-skeleton-with-avatar)) {
    :global(.ant-skeleton-title) {
      margin: 0;
    }
  }

  &:global(.ant-skeleton-with-avatar .ant-skeleton-title) {
    margin-bottom: 0;
  }

  &:global(.ant-skeleton-with-avatar
      .ant-skeleton-title
      + .ant-skeleton-paragraph) {
    margin-top: var(--spacing-base);
  }

  &.vCenter {
    display: flex;
    align-items: center;
  }
}
