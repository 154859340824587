/* BASE ELEMENTS */

a {
  font-weight: var(--font-bold);
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* ANTD ELEMENTS */

.ant-form-item > .ant-form-item-label,
.ant-form-item > .ant-form-item-label > label {
  font-size: var(--font-xs);
}
