.animatedAppLogo {
  width: 150px;
  height: 25px;
  position: relative;
  overflow: hidden;

  .logo {
    scale: 0.1;
    position: absolute;
    left: 50px;

    &.expand {
      scale: 1;
      transition: scale 400ms ease-in-out;
    }

    &.slideLeft {
      left: 0;
      transition: left 400ms ease-in-out;
    }
  }

  .brand {
    position: absolute;
    left: 45px;
    top: 25px;

    &.slideUp {
      top: 3px;
      transition: top 700ms ease-in-out;
    }
  }
}
