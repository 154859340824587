.viewProfile {
  .avatarEmpty {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--gray-lighter);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--spacing-sm) 0;
  }

  .row {
    $icon-size: 28px;

    padding: var(--spacing-base) 0;
    margin-left: calc(#{$icon-size} + var(--spacing-base));
    border-bottom: var(--border-solid-light);
    position: relative;

    .rowIcon {
      position: absolute;
      left: calc(-1 * (#{$icon-size} + var(--spacing-base)));
      top: 50%;
      transform: translateY(-50%);
      height: $icon-size;
      width: $icon-size;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--gray-lightest);
    }
  }

  .spinner {
    height: 402px;
  }
}
