.sessionTimeoutModal {
  position: relative;

  .icon {
    position: absolute;
    margin-top: var(--spacing-2xs);
  }

  .content {
    padding-left: var(--spacing-2xl);
  }

  .heading {
    margin: var(--spacing-xs) 0;
  }

  .actions {
    display: flex;
    margin-top: var(--spacing-sm);
    button + button {
      margin-left: var(--spacing-xs);
    }
  }
}
