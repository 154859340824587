.icon {
  display: inline-block;
  font-size: var(--icon-base);
  color: var(--gray);
  width: 1em;
  height: 1em;
  position: relative;

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &.button {
    cursor: pointer;
  }

  &.xs {
    font-size: var(--icon-xs);
  }

  &.sm {
    font-size: var(--icon-sm);
  }

  &.md {
    font-size: var(--icon-md);
  }

  &.lg {
    font-size: var(--icon-lg);
  }

  &.xl {
    font-size: var(--icon-xl);
  }

  &.light {
    color: var(--gray-light);
  }

  &.lighter {
    color: var(--gray-lighter);
  }

  &.lightest {
    color: var(--gray-lightest);
  }

  &.black {
    color: var(--black);
  }

  &.white {
    color: var(--white);
  }

  &.primary {
    color: var(--default);
  }

  &.primaryLighter {
    color: var(--default-lighter);
  }

  &.success {
    color: var(--success);
  }

  &.danger {
    color: var(--danger);
  }

  &.brown {
    color: var(--brown);
  }

  &.warning {
    color: var(--warning);
  }
}
