.modal {
  :global(.ant-modal-content .ant-modal-body) {
    padding: 0;
    position: relative;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
      border-bottom: var(--border-solid-light);

      .closeIcon {
        position: absolute;
        left: var(--spacing-base);
      }
    }

    .content {
      padding: var(--spacing-lg);
    }
  }

  &.noHeader {
    :global(.ant-modal-content .ant-modal-body) {
      .closeIcon {
        position: absolute;
        top: var(--spacing-base);
        right: var(--spacing-base);
        color: var(--default);
      }

      .content {
        padding: var(--spacing-2xl) var(--spacing-2xl) var(--spacing-lg);
      }
    }
  }
}
