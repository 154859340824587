.button {
  font-size: var(--font-sm);
  padding-top: 0;
  padding-bottom: 0;

  &.block {
    display: block;
  }

  &.shadow {
    box-shadow: 0 2px 4px 0 var(--shadow);
  }

  &.vCenter {
    display: flex;
    align-items: center;
  }

  &.secondary:not(:disabled) {
    color: var(--primary);
    background-color: var(--primary-10);
  }

  &.outlined:not(:disabled) {
    color: var(--default);
    border: 1px solid var(--default);

    &:hover {
      color: var(--default-80);
      border: 1px solid var(--default-80);
    }
  }

  &.outlinedGray:not(:disabled) {
    color: #a0aec0;
    border: 1px solid #a0aec0;

    &:hover {
      color: var(--default-80);
      border: 1px solid var(--default-80);
    }
  }

  &.light:not(:disabled) {
    background-color: var(--offwhite);
  }

  &.link {
    padding: 0;
    height: auto;
    font-size: var(--font-base);
  }

  &.small {
    font-size: var(--font-xs);
  }
}
