.sidebar {
  width: 300px;
  padding: var(--spacing-xl) 0;
  border-right: var(--border-solid-light);
  display: flex;
  flex-direction: column;

  // COLLAPSE
  transition: width var(--transition-normal) ease-out;

  .header {
    padding: 0 var(--spacing-xl);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &[role='button'] {
      cursor: pointer;
    }

    .appLogo {
      width: 108px;
    }
  }

  .partner {
    padding: var(--spacing-lg) var(--spacing-base);
    margin-top: var(--spacing-2xs);
    border-bottom: var(--border-solid-light);

    // COLLAPSE
    transition: background-color var(--transition-short) ease-out;

    .logoContainer {
      padding: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-sm);
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: var(--white-darker);
      border-radius: 8px;

      .partnerLogo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: relative;

        // COLLAPSE
        transition: width var(--transition-short) ease-out,
          height var(--transition-short) ease-out;

        .partnerLogoEmpty {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          right: 2px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-color: var(--success);
          border: 3px solid var(--white);
        }
      }

      .textContainer {
        margin-left: var(--spacing-sm);
        width: 170px;

        .nameText,
        .roleText {
          color: #5a5a5a;
        }

        .roleText {
          font-weight: normal;
        }
      }
    }

    .skeletonPartnerLogo {
      width: 60px;
      height: 60px;
    }

    .partnerInfo {
      display: block;
      margin: var(--spacing-base) 0 0;
      padding: var(--spacing-sm) var(--spacing-xs);
      border-radius: var(--border-radius);
      // background-color: var(--offwhite);
      cursor: pointer;

      transition: transform var(--transition-short) ease-out,
        background-color var(--transition-short) ease-out;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &:hover {
        transform: translateY(calc(-1 * var(--spacing-3xs)));
        background-color: var(--default-lighter-10);
        &::before {
          height: calc(100% + var(--spacing-3xs));
        }
      }

      .profileCompletion {
        font-weight: normal;
        margin-top: var(--spacing-xs);
      }

      .schoolIconGroup {
        padding-left: var(--spacing-sm);
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .doubleIcon {
          width: 50px;
          height: 100%;
          position: relative;

          .firstIcon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }

          .secondIcon {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
          }
        }

        .tripleIcon {
          width: 70px;
          height: 100%;
          position: relative;

          .firstIcon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
          }

          .secondIcon {
            position: absolute;
            top: 0;
            left: 20px;
            z-index: 2;
          }

          .thirdIcon {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
          }
        }

        .quadrupleIcon {
          width: 92px;
          height: 100%;
          position: relative;

          .firstIcon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
          }

          .secondIcon {
            position: absolute;
            top: 0;
            left: 20px;
            z-index: 3;
          }

          .thirdIcon {
            position: absolute;
            top: 0;
            left: 40px;
            z-index: 2;
          }

          .fourthIcon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid var(--white);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--black-lightest);

            .fourthIconText {
              font-weight: normal;
            }
          }
        }

        .schoolIconContainer {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 3px solid var(--white);
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12948);

          .schoolIcon {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .emptySchoolIconContainer {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--gray-lighter);
          border: 3px solid var(--white);
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12948);
        }

        .schoolIconText {
          margin-left: 12px;
          font-weight: normal;
          color: var(--new-gray-darker);
        }
      }
    }

    .skeletonParagraph li {
      margin-left: var(--spacing-base);
      height: 30px;
    }
  }

  .menu {
    padding: var(--spacing-lg) 0;

    .label {
      padding: 0 var(--spacing-xl);
      margin-bottom: var(--spacing-md);
    }

    .itemContainer {
      padding: 0 var(--spacing-base);
      position: relative;

      .highlight {
        background-color: var(--white);
        z-index: 4;
      }

      &.itemContainerActive {
        background-color: var(--white-darker);

        .highlight {
          background-color: transparent;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          height: 100%;
          width: 4px;
          background-color: var(--default);
          border-radius: 2px;
        }
      }

      &.itemContainerActiveHasSubMenu {
        background-color: var(--white-darker);

        .highlight {
          background-color: transparent;
        }

        &::before {
          content: '';
          height: 30%;
          position: absolute;
          left: 0;
          top: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          background-color: var(--default);
          border-radius: 2px;
        }
      }

      .item {
        padding: var(--spacing-sm) var(--spacing-base);
        border-radius: var(--border-radius);
        margin-bottom: var(--spacing-xs);
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        :global {
          .ant-tag {
            color: #4c51bf;
            background-color: #c3dafe;
            border: none;
          }
        }

        transition: transform var(--transition-short) ease-out;
        &::before {
          content: '';
          position: absolute;
          right: 0;
          height: 100%;
          width: 100%;
        }
        .itemIcon,
        .itemLabel {
          transition: color var(--transition-short) ease-out;
        }
        &:hover:not(.itemActive) {
          transform: translateX(var(--spacing-2xs));
          &::before {
            width: calc(100% + var(--spacing-2xs));
          }
          .itemIcon,
          .itemLabel {
            color: var(--default-lighter);
          }
        }

        &.itemActive {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: calc(-1 * var(--spacing-xl));
            bottom: 0;
            width: 4px;
            background-color: var(--default-lighter);
          }

          .itemLabel {
            font-weight: var(--font-bold);
          }
        }

        .itemIcon {
          flex-shrink: 0;
        }

        .itemLabel {
          width: 100%;
          font-weight: normal;
          padding-left: var(--spacing-sm);
        }

        .VARequestBadge {
          height: 22px;
          width: 22px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: var(--font-xs);
          background-color: var(--danger);
          color: var(--white);
        }
      }
    }
  }

  .footer {
    margin: 0 var(--spacing-base);
    padding: var(--spacing-lg) var(--spacing-base) 0;
    border-top: var(--border-solid-light);

    .footerContent {
      display: flex;
      align-items: center;
      cursor: pointer;

      position: relative;
      transition: transform var(--transition-short) ease-out;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
      }
      .logoutIcon,
      .logout {
        transition: color var(--transition-short) ease-out;
      }
      &:hover {
        transform: translateX(var(--spacing-2xs));
        &::before {
          width: calc(100% + var(--spacing-2xs));
        }
        .logoutIcon,
        .logout {
          color: var(--default-lighter);
        }
      }

      .logout {
        margin-left: var(--spacing-sm);
      }
    }
  }

  .layer {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--true-black-60);
  }
}

.sidebarCollapsed {
  width: 82px;
  padding: 0;
  align-items: center;

  .header {
    justify-content: center;
    align-self: stretch;
    padding: var(--spacing-sm) 0;
    margin-top: calc(var(--spacing-xl) - var(--spacing-sm));
    position: relative;

    .appLogo {
      width: 32px;
    }

    .menuIcon {
      position: absolute;
      transform: translateX(-50%);
      left: 0;
    }
  }

  .partner {
    padding: var(--spacing-sm);
    margin-top: var(--spacing-sm);
    border-bottom: none;
    width: 100%;
    display: flex;
    justify-content: center;

    .logoContainer {
      padding: 0;
      background-color: transparent;

      .partnerLogo {
        width: 40px;
        height: 40px;

        &::before {
          display: none;
        }
      }
    }

    .skeletonPartner {
      display: flex;
      justify-content: center;

      .skeletonPartnerLogo {
        width: 40px;
        height: 40px;
      }
    }

    .partnerInfo {
      display: none;
    }

    .textContainer {
      display: none;
    }
  }

  .menu {
    padding: var(--spacing-md) 0;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .itemContainer {
      .highlight {
        background-color: var(--white);
        z-index: 4;
      }

      &.itemContainerActive {
        background-color: transparent;

        .highlight {
          background-color: transparent;
        }
      }

      .item {
        &::before {
          display: none;
        }

        &:hover:not(.itemActive) {
          transform: none;
        }

        &.itemActive {
          &::after {
            right: calc(-1 * var(--spacing-lg));
            transform: translateX(-50%);
          }
        }

        .itemLabel {
          display: none;
        }
      }
    }
  }

  .user {
    &::before {
      display: none;
    }

    &:hover {
      transform: none;
    }

    .userInfo {
      display: none;
    }
  }

  .footer {
    padding: 0;
    width: 100%;
    height: 82px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    .footerContent {
      padding: var(--spacing-xs);

      &::before {
        display: none;
      }

      &:hover {
        transform: none;
      }

      .logout {
        display: none;
      }
    }
  }
}

.modalFakeDoor {
  text-align: center;
  img {
    margin-bottom: var(--spacing-md);
  }

  p {
    margin: var(--spacing-md) 0;
  }

  button + button {
    margin-left: var(--spacing-md);
  }
}

.coachMarking {
  padding: var(--spacing-2xs) var(--spacing-2xs);
  width: 385px;

  .content {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-base);
  }
}

.schoolList {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    padding-left: 1em;
    text-indent: -1em;
  }

  li:before {
    margin-right: var(--spacing-xs);
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--default);
  }
}

.subMenuContainer {
  .subMenuItem {
    color: var(--black);
    font-weight: normal;
    cursor: pointer;
    padding: 0 calc(var(--spacing-3xl) - 5px);
    transition: all ease 0.3s;

    &:hover {
      color: var(--primaryDark);
      margin-left: var(--spacing-2xs);
    }

    .subMenuItemLabel {
      font-size: var(--font-sm);
    }
  }
}
