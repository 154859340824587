.spinner {
  .icon {
    color: var(--primary);
    font-size: var(--icon-base);
  }

  &.centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.xs {
    .icon {
      font-size: var(--icon-xs);
    }
  }

  &.xl {
    .icon {
      font-size: var(--icon-xl);
    }
  }
}
