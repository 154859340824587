@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima_nova_regular-webfont.woff2') format('woff2'),
    url('../fonts/proxima_nova_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
    url('../fonts/proxima_nova_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
