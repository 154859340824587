.tooltip {
  :global {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        padding: var(--spacing-xs);
        min-width: auto;
        min-height: auto;
      }
    }
  }

  &.danger {
    :global(.ant-tooltip-content .ant-tooltip-inner) {
      background-color: var(--danger);
    }
  }

  &.light {
    :global(.ant-tooltip-content .ant-tooltip-inner) {
      background-color: var(--gray-light);
    }
  }

  &.primary {
    :global(.ant-tooltip-content .ant-tooltip-inner) {
      background-color: var(--default-lighter);
    }
  }

  &.success {
    :global(.ant-tooltip-content .ant-tooltip-inner) {
      background-color: var(--success);
    }
  }

  &.warning {
    :global(.ant-tooltip-content .ant-tooltip-inner) {
      background-color: var(--warning);
    }
  }
}
